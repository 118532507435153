import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();



/* ====================================================
   DOSING CALCULATOR  --RA- PSA -AS
   ==================================================== */ 
var dose=0;
var cal_lb =95;
var cal_kg=43;
var cal_mg=0;
var cal_vials=0;
var cal_ml=0;
$(document).ready(function($){
  
        // get dose value - only use by RA
        $( ".does" ).change(function () {
        /*  $( "select option:selected" ).each(function() {
            dose = $( this ).val();
            });*/
            dose=$(".does option:selected" ).text();
            
            $(".result_mg").html( "" );
            $(".result_vials").html( "" );
            $(".result_ml").html( "" );
            
        }).change();

        // convet lb to kg
        $( ".cal_lb" ).change(function () {
         
              cal_lb=$(".cal_lb option:selected" ).text();
              cal_kg = Math.round(cal_lb* (0.453592));
     
            $( ".kg" ).html( cal_kg );
            $( ".result_mg" ).html( "" );
            $( ".result_vials" ).html( "" );
            $( ".result_ml" ).html( "" );
            
          }).change();

                $('.enter_btn').on('click', function (e) {
                     
                     if($(this).hasClass("psa") || $(this).hasClass("as") ){  // PSA and AS
                         dose = 5;
                     }
                     else{ // ra
                          dose = $( "#RA_dose" ).val();
                     }
                      cal_mg = dose * cal_kg;
                      cal_vials =Math.ceil(cal_mg/100);
                      cal_ml=cal_mg/10;     
                         $( ".result_mg" ).html( cal_mg );
                         $( ".result_vials" ).html( cal_vials );
                         $( ".result_ml" ).html( cal_ml );
    //console.log("line 78 enter clicked-- " + cal_lb + "-" + cal_kg + "-" + cal_dose+"-" +cal_mg + "-" + cal_vials + "-" + cal_ml);
                   
                 });


});

/* ====================================================
    VIDEO
   ==================================================== */ 

  $(document).ready(function($){
        
  videojs("myPlayerID").ready(function(){
  var player = this;
  player.one("loadedmetadata",function(){
  $("#pic1").click(function() {
  player.currentTime(0);
    var tt = player.textTracks()[0];
    tt.oncuechange = function() {
      if(tt.activeCues[0] !== undefined){
                      var dynamicHTML = "id: " + tt.activeCues[0].id + ", ";
                      dynamicHTML += "text: " + tt.activeCues[0].text + ", ";
                      dynamicHTML += "startTime: " + tt.activeCues[0].startTime + ",  ";
                      dynamicHTML += "endTime: " + tt.activeCues[0].endTime;
                      document.getElementById("insertionPoint").innerHTML += dynamicHTML + "<br/>";
      if(tt.activeCues[0].endTime > 369)
      {
      player.pause();
      }
      
      }
     }
      player.play();
      });

      
	
   $('.close-button').click(function(){
           player.pause();
            });
    $( "#pic2" ).click(function() {
          player.currentTime(370);
            player.play();
            });
         
	$( "#pic5" ).click(function() {
          player.currentTime(1650);
            player.play();
            });
    $( "#pic6" ).click(function() {
          player.currentTime(1907);
            player.play();
            });			
    $( "#pic4" ).click(function() {
          player.currentTime(1227);
            player.play();
            });     
			
			$( "#pic3" ).click(function() {
                player.currentTime(761);
  
                  var tt = player.textTracks()[0];
                    tt.oncuechange = function() {
                      if(tt.activeCues[0] !== undefined){
                        var dynamicHTML = "id: " + tt.activeCues[0].id + ", ";
                        dynamicHTML += "text: " + tt.activeCues[0].text + ", ";
                        dynamicHTML += "startTime: " + tt.activeCues[0].startTime + ",  ";
                        dynamicHTML += "endTime: " + tt.activeCues[0].endTime;
                        document.getElementById("insertionPoint").innerHTML += dynamicHTML + "<br/>";
                        }
                                }
                player.play();
                });
                
                });
                
});  

    });


/* ====================================================
    Adding class to mobile main menu
   ==================================================== */ 
$(document).ready(function($){
        // Get current url
        // Select an a element that has the matching href and apply a class of 'active'. Also prepend a - to the content of the link
        var url = window.location.href;

        // Will also work for relative and absolute hrefs
       

        $('ul.menu a').each(function () {
        if (this.href == window.location.href) {
            $(this).parents('li').find('> a').addClass("active");
        }
        });
    
    
        // for POPUP button remove is-reveal-open class from html prevent content disappear 
      $('.popup').each(function () {
         $(this).on('click', function (e) {
             setTimeout(function () {
              $('html').removeClass('is-reveal-open'); 
             }, 30);
         });
      });
    
        // hide product menu

    $('.product_home').on('click', function (e) {
        $(this).toggleClass('on');
        $('.product_menu').toggleClass('hide');
        $('.product_overlay').toggleClass('hide');
        
    });
    
    $('.product_overlay').on('click', function (e) {
        $(this).removeClass('on');
        $('.product_home').removeClass('on');
        $('.product_menu').addClass('hide');
        $('.product_overlay').addClass('hide');
        
        
        
    });
    
    // for herf link to #tag in different page
     setTimeout(function () {
           $('html,body').animate({
             scrollTop: $(window.location.hash).offset().top-160
           });
         console.log("jumplink");
     },300);
    
    
    //==== for hash in same page pad top 
   
    $('.inpageHash').click(function(){
        console.log("inpageHash")
        var id =$(this).attr("href"),
        $id = $(id),
        dataID = id.substr(1);
       // Our scroll target : the top position of the
        // section that has the id referenced by our href.
        var target = $(id).offset().top - 120;

        //$id.attr('data-id',dataID).attr('id','');
        //window.location.hash = id;
       /// $id.attr('id',dataID);

        $('html,body').animate({scrollTop: target},500);
      });
    
    //====== hide mobile menu when click elsewhere 
    
    $('.menu-icon').click(function(){
         $('body').toggleClass('mobile-nav-opened');
         return false;
    });
    
    $('html').click(function() {
        if ($('body').hasClass('mobile-nav-opened')){
            $('#mobile-menu').css('display', 'none');
             $('body').removeClass('mobile-nav-opened');
            }
        });
  
        $('#mobile-menu').click(function(e){
            e.stopPropagation();
        });
    
});

/* ====================================================
   window scroll hide products popup
   ==================================================== */ 

$(window).scroll(function() {
     // if scroll is greater/equal then 10 hide products popup
        if ($(this).scrollTop() > 10) { 
            $('.product_home').removeClass('on');
             $('.product_menu').addClass('hide');
            $('.product_overlay').addClass('hide');
            $('.nav-bar').addClass('is-stuck');
        }
         if ($(this).scrollTop() == 0) { 
              $('.nav-bar').removeClass('is-stuck');
         }
    
    });


/* ====================================================
    External Link Modal Pop Ups
   ==================================================== */

/* ===========================
    Third Party Links
   =========================== */
$('a.extlink').on("mousedown touchstart", function(e) {
    e.preventDefault(); // Prevent the href from redirecting directly
    var linkURLThirdparty = $(this).attr("href");
    warnBeforeRedirectThirdparty(linkURLThirdparty);
});

window.warnBeforeRedirectThirdparty = function warnBeforeRedirectThirdparty(linkURLThirdparty) {
    swal({
        title: "Thank you for visiting www.remicadehcp.com.",
        html: "<p>By clicking “continue” below, you will be taken to a Web site that may contain links or references to other Web sites to which our Privacy Policy may not apply. We encourage you to read the Privacy Policy of every Web site you visit.</p><p>You are solely responsible for your interactions with such Web sites</p>",
        cancelButtonText: 'Cancel >',
        confirmButtonText: 'Continue >', 
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: true,
        useRejections: true,
        animation: false

    }).then(okay => {
      // Redirect the user
      if (okay) {
        window.open(linkURLThirdparty);
      }
    });
};






/* ====================================================
   ISI
   ==================================================== */ 

var isiwaypoint = new Waypoint({
  element: document.getElementById('isi-waypoint'),
  handler: function(direction) {
       if(direction == 'down') {
           $('#section-isi').hide();
       }else if(direction == 'up'){
           $('#section-isi').show();
           
       }
    //console.log('Direction: ' + direction);
  },
  offset: '75%'
    
})


//--------------BIND BUTTONS
$(window).on("load",function(){  

  setTimeout(function () {
    $(window).scrollTop(0); //scroll to top on refresh
  }); 
    
   $('.backtotop').on('click', function (e) {
       $(window).scrollTop(0);
   });   
    
  $('.isiexpand').on('click', function (e) {
      // window.scrollTo(0,document.body.scrollHeight);
      e.preventDefault()
      $('html, body').animate(
          {
            scrollTop: $("#isi-waypoint").offset().top -300,
          }, 300,'linear' );
     }); 
    
    /// QAbtn on access page 
     $('.QAbtn').on('click', function (e) {
         $(this).siblings().removeClass('on');
       $(this).toggleClass('on');
         
   }); 
    
    
    
});


//----- mobile ISI -----------------------

$(window).on("resize",function(){
    
  if($(this).width()>1023){
       $('#section-isi').removeClass('mob_isi');
       $(".s_header").unbind();

  }

  else if($(this).width() <= 1023) {  // add mobile style and button in ISI and IND
      $('#section-isi').addClass('mob_isi');
         $('.s_header').on('click', function (e) {
                  //$('.mob_isi').toggleClass('open');
                  $(this).toggleClass('isiOn');
             
          if($(this).hasClass('ind') ){ // =========f mobile IND clicked 

                 //--if ind already open close it
                  if($('.sIND').hasClass('On')){ 
                      $('.mob_isi').removeClass('open');
                      $('.sIND').removeClass('On');
                  }else{
                      $('.mob_isi').addClass('open');
                      $('.sIND').addClass('On');
                      }
                
                  //---if ISI already on - close it 
                  if($('.sISI').hasClass('On')){ 
                      $('.sISI').removeClass('On');
                      $('.s_header.isi').removeClass('isiOn');
                      
                      
                  }else{ // ---if ISI not open keep it in place
                   
                  }
                    
              
            }else{ //============================= if mobile ISI clicked  
                
                //--if isi already open close it
                if($('.sISI').hasClass('On')){ 
                      $('.mob_isi').removeClass('open');
                      $('.sISI').removeClass('On');
                      $('.s_header.ind').removeClass('isiOn');
                  }else{
                      $('.mob_isi').addClass('open');
                      $('.sISI').addClass('On');
                     
                      }
                
                //---if IND already on close it 
                if($('.sIND').hasClass('On')){
                    $('.sIND').removeClass('On');
                      $('.s_header.ind').removeClass('isiOn');
                  }else{
                  
                  }
            }
        });
                                       
      console.log("small " + $(this).width());
  }

}).trigger("resize");


